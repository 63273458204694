<script setup lang="ts">
import { ExclamationCircleIcon } from "@heroicons/vue/20/solid";

withDefaults(
  defineProps<{
    modelValue?: string | number | string[] | undefined;
    id: string;
    label?: string;
    name?: string;
    placeholder?: string;
    description?: string;
    rows?: number;
    cols?: number;
    v?: any;
  }>(),
  {
    rows: 10,
    cols: 50,
  }
);
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", (e.target as HTMLInputElement).value);
};
</script>
<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="block text-sm font-medium text-gray-700"
      >{{ label }}</label
    >
    <div class="relative rounded-md shadow-sm" :class="label ? 'mt-1' : 'mt-0'">
      <textarea
        :id="id"
        :name="name || id"
        class="block w-full border border-gray-300 rounded-md pr-10 sm:text-sm"
        :class="
          v && v.$error
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
            : ''
        "
        :value="modelValue"
        :aria-invalid="v && v.$error"
        :aria-describedby="`${id}-error`"
        :rows="`${rows}`"
        :cols="`${cols}`"
        @input="updateValue"
      >
      </textarea>
      <div
        v-if="v && v.$error"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <ExclamationCircleIcon
          class="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      </div>
    </div>
    <p v-if="description" class="mt-1 text-sm text-gray-500">
      {{ description }}
    </p>
    <p
      v-if="v && v.$error"
      :id="`${id}-error`"
      class="mt-2 text-sm text-red-600"
    >
      {{ v.$errors[0].$message }}
    </p>
  </div>
</template>
