<script setup lang="ts">
import { getDocs, orderBy, query, where } from "firebase/firestore";
import { useCollection } from "vuefire";
import { computed, ref } from "vue";
import { format } from "date-fns";
import db from "@/firebase/util";
import Heading from "@/components/ui/HeadingUI.vue";

import type { PracticeTimeSlot } from "@/types/sesc";
import { formatTime } from "@/utils";

const today = format(new Date(), "EEEE");
const todaysPracticeSchedules = ref<PracticeTimeSlot[]>([]);

const todaysDate = computed(() => format(new Date(), "yyyy-MM-dd"));
const practiceSchedule_q = query(
  db.practiceSchedules,
  where("startDate", "<=", todaysDate.value),
  where("endDate", ">=", todaysDate.value),
);
const { data: practiceSchedules, pending } = useCollection(practiceSchedule_q, {
  ssrKey: "admin-dashboard-practice-schedules",
});

practiceSchedules.value?.forEach((practiceSchedule) => {
  const pts_q = query(
    db.practiceTimeSlots(practiceSchedule.id),
    orderBy("startTime"),
  );
  getDocs(pts_q).then((timeSlotsSnapshot) => {
    timeSlotsSnapshot.forEach((timeSlot) => {
      const timeSlotData = timeSlot.data();
      if (timeSlotData.dayOfTheWeek === today) {
        todaysPracticeSchedules.value.push(timeSlotData);
      }
    });
  });
});
</script>
<template>
  <div>
    <Heading size="text-xl" color="text-white"
      >Today's Practice Schedule</Heading
    >
    <p class="text-white">Who is practicing today and where?</p>
    <ul v-if="!pending" class="mt-4 bg-white rounded p-5 divide-y">
      <li
        v-for="timeSlot in todaysPracticeSchedules"
        :key="timeSlot.id"
        class="list-item mb-8 last:mb-0 pt-6 first:pt-0"
      >
        <div class="flex justify-between">
          <Heading size="text-lg">{{ timeSlot.venue }}</Heading>
          <div>
            <span
              class="inline-flex items-center rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700"
              >{{ formatTime(timeSlot.startTime) }}</span
            >
            -
            <span
              class="inline-flex items-center rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700"
              >{{ formatTime(timeSlot.endTime) }}</span
            >
          </div>
        </div>
        <div>
          <p class="text-sm/6 text-gray-700">{{ timeSlot.field }}</p>
        </div>
        <div class="mt-2 flex gap-2 flex-col">
          <span
            v-for="team in timeSlot.teams"
            :key="team"
            class="inline-flex items-center rounded-full bg-blue-50 px-3 py-2 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
            >{{ team }}</span
          >
        </div>
      </li>
    </ul>
  </div>
</template>
