import { FirebaseError } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  type Auth,
  type User as FBUser,
} from "firebase/auth";
import {
  deleteDoc,
  getDoc,
  doc,
  setDoc,
  query,
  where,
  orderBy,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { auth } from "@/firebase/config";
import { db, prepareData } from "@/firebase/util";
import { format } from "date-fns";

import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import type { ActionTree, ActionContext } from "vuex";
import type { State } from "./state";
import type { Mutations } from "./mutations";
import type {
  User,
  Team,
  VeoReservation,
  AppSetting,
  Player,
  Season,
} from "@/types/sesc";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
  ): ReturnType<Actions[K]>;
} & Omit<ActionContext<State, State>, "commit" | "dispatch">;

export interface Actions {
  [ActionTypes.GET_APP_SETTINGS]({ commit }: AugmentedActionContext): void;
  [ActionTypes.UPDATE_APP_SETTINGS](
    { commit }: AugmentedActionContext,
    settings: AppSetting,
  ): void;
  [ActionTypes.RESET_RESERVATION]({ commit }: AugmentedActionContext): void;
  [ActionTypes.REGISTER](
    { commit }: AugmentedActionContext,
    {
      email,
      password,
      name,
    }: { email: string; password: string; name: string },
  ): void;
  [ActionTypes.LOG_IN_WITH_PASSWORD](
    { dispatch }: AugmentedActionContext,
    { email, password }: { email: string; password: string },
  ): void;
  [ActionTypes.LOG_IN_WITH_GOOGLE]({ dispatch }: AugmentedActionContext): void;
  [ActionTypes.LOG_OUT]({ commit }: AugmentedActionContext): void;
  [ActionTypes.FETCH_USER](
    { commit, dispatch }: AugmentedActionContext,
    payload: FBUser,
  ): void;
  [ActionTypes.IS_USER_ADMIN](
    { commit }: AugmentedActionContext,
    payload: FBUser,
  ): void;
  [ActionTypes.REFRESH_USER](
    { dispatch }: AugmentedActionContext,
    payload: Auth,
  ): void;
  [ActionTypes.GET_USER_META](
    { commit }: AugmentedActionContext,
    {
      email,
      uid,
      displayName,
      photoURL,
      phoneNumber,
    }:
      | {
          email: string | null;
          uid: string;
          displayName: string | null;
          photoURL: string | null;
          phoneNumber: string | null;
        }
      | FBUser,
  ): void;
  [ActionTypes.GET_USER_RESERVATIONS](
    { commit }: AugmentedActionContext,
    { email }: { email: string } | FBUser,
  ): void;
  // [ActionTypes.GET_TEAMS]({ commit }: AugmentedActionContext): void;
  [ActionTypes.EDIT_TEAM](
    { commit, dispatch }: AugmentedActionContext,
    payload: Team,
  ): void;
  [ActionTypes.DELETE_TEAM](
    { dispatch }: AugmentedActionContext,
    payload: Team,
  ): void;
  [ActionTypes.GET_PLAYERS]({ commit }: AugmentedActionContext): void;
  [ActionTypes.EDIT_PLAYER](
    { dispatch }: AugmentedActionContext,
    payload: Partial<Player>,
  ): void;
  [ActionTypes.DELETE_PLAYER](
    { dispatch }: AugmentedActionContext,
    payload: Player,
  ): void;
  [ActionTypes.ADD_PLAYER_TO_TEAM](
    context: AugmentedActionContext,
    payload: { teamId: string; playerId: string },
  ): void;
  [ActionTypes.REMOVE_PLAYER_FROM_TEAM](
    context: AugmentedActionContext,
    payload: { teamId: string; playerId: string },
  ): void;
  [ActionTypes.DELETE_RESERVATION](
    { dispatch }: AugmentedActionContext,
    payload: VeoReservation,
  ): void;
}

export const actions: ActionTree<State, State> & Actions = {
  async [ActionTypes.GET_APP_SETTINGS]({ commit }) {
    const settingsSnap = await getDoc(db.app("settings"));
    if (settingsSnap.exists()) {
      commit(MutationTypes.SET_APP_SETTINGS, settingsSnap.data());
    }
  },
  async [ActionTypes.UPDATE_APP_SETTINGS]({ commit }, settings) {
    await setDoc(db.app("settings"), settings);
    commit(MutationTypes.SET_APP_SETTINGS, settings);
  },
  [ActionTypes.RESET_RESERVATION]({ commit }) {
    commit(MutationTypes.SET_RESERVATION_DATE, null);
    commit(MutationTypes.SET_RESERVATION_CAMERA, "");
  },
  async [ActionTypes.REGISTER]({ commit }, { email, password }) {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    if (response) {
      commit(MutationTypes.SET_USER, response.user);
    } else {
      throw new Error("Unable to register user");
    }
  },
  async [ActionTypes.LOG_IN_WITH_PASSWORD]({ dispatch }, { email, password }) {
    const response = await signInWithEmailAndPassword(auth, email, password);
    if (response) {
      await dispatch(ActionTypes.FETCH_USER, response.user);
      // commit(MutationTypes.SET_USER, response.user);
      // commit(MutationTypes.SET_LOGGED_IN, true);
    } else {
      throw new Error("login failed");
    }
  },
  async [ActionTypes.LOG_IN_WITH_GOOGLE]({ dispatch }) {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    try {
      const result = await signInWithPopup(auth, provider);

      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const user = result.user;
      await dispatch(ActionTypes.FETCH_USER, user);
    } catch (err) {
      if (err instanceof FirebaseError) {
        const errorCode = err.code;
        const errorMessage = err.message;
        const credential = GoogleAuthProvider.credentialFromError(err);
        throw new Error(errorMessage);
      } else {
        throw new Error(err as string);
      }
    }
  },
  async [ActionTypes.LOG_OUT]({ commit }) {
    await signOut(auth);
    commit(MutationTypes.SET_LOGGED_IN, false);
    commit(MutationTypes.SET_IS_ADMIN, false);
    commit(MutationTypes.SET_IS_DIRECTOR, false);
    commit(MutationTypes.SET_USER, null);
  },
  async [ActionTypes.FETCH_USER]({ commit, dispatch }, user) {
    commit(MutationTypes.SET_LOGGED_IN, user !== null);
    if (user) {
      commit(MutationTypes.SET_USER, user);
      dispatch(ActionTypes.IS_USER_ADMIN, user);
      dispatch(ActionTypes.GET_USER_META, user);
      dispatch(ActionTypes.GET_USER_RESERVATIONS, user);
      dispatch(ActionTypes.GET_APP_SETTINGS);
    } else {
      commit(MutationTypes.SET_USER, null);
    }
  },
  async [ActionTypes.IS_USER_ADMIN]({ commit }, user) {
    try {
      const idTokenResult = await user?.getIdTokenResult();
      if (idTokenResult?.claims?.admin) {
        commit(MutationTypes.SET_IS_ADMIN, true);
      } else if (idTokenResult?.claims?.director) {
        commit(MutationTypes.SET_IS_DIRECTOR, true);
      } else {
        commit(MutationTypes.SET_IS_ADMIN, false);
        commit(MutationTypes.SET_IS_DIRECTOR, false);
      }
    } catch (error) {
      console.error(error);
      commit(MutationTypes.SET_IS_ADMIN, false);
      commit(MutationTypes.SET_IS_DIRECTOR, false);
    }
  },
  async [ActionTypes.REFRESH_USER]({ dispatch }, auth) {
    const user = auth.currentUser;
    if (user) {
      dispatch(ActionTypes.FETCH_USER, user);
    }
  },
  async [ActionTypes.GET_USER_META](
    { commit },
    { email, uid, displayName, photoURL, phoneNumber },
  ) {
    const usersnapshot = await getDoc(db.user(uid));
    if (!usersnapshot.exists()) {
      const userData = {
        firstName: "",
        lastName: "",
        displayName: displayName || "",
        pronouns: "",
        address: {
          streetAddress: "",
          streetAddress2: "",
          city: "",
          state: "",
          zip: "",
        },
        email: email || "",
        role: "user",
        image: photoURL || "",
        phone: phoneNumber || "",
      };
      await setDoc(doc(db.users, uid), userData);
      commit(MutationTypes.SET_USER_META, userData as User);
    } else {
      const userData = await usersnapshot.data();
      commit(MutationTypes.SET_USER_META, userData);
    }
  },
  async [ActionTypes.GET_USER_RESERVATIONS]({ commit }, { email }) {
    const todaysDate = format(new Date(), "yyyy-MM-dd");
    const q = query(
      db.reservations,
      where("contact.email", "==", email),
      where("date", ">=", todaysDate),
      orderBy("date"),
    );
    const reservationsSnap = await getDocs(q);
    commit(
      MutationTypes.SET_USER_RESERVATIONS,
      reservationsSnap.docs.map((snap) => ({ id: snap.id, ...snap.data() })),
    );
  },
  // async [ActionTypes.GET_TEAMS]({ commit }) {
  //   // query teams
  // const teamsSnap = await getDocs(db.teams);
  // const teams: Team[] = await Promise.all(
  //   teamsSnap?.docs?.map<Promise<Team>>(async (team) => {
  //     return new Promise((resolve, reject) => {
  //       const teamData = { id: team.id, ...team.data() };
  //       if (teamData?.currentSeason) {
  //         getDoc(db.season(team.id, teamData.currentSeason as string)).then(
  //           (seasonSnap) => {
  //             if (seasonSnap.exists()) {
  //               teamData.currentSeason = {
  //                 id: seasonSnap.id,
  //                 ...seasonSnap.data(),
  //               };
  //               resolve(teamData);
  //             } else {
  //               reject();
  //             }
  //           },
  //         );
  //       } else {
  //         resolve(teamData);
  //       }
  //     });
  //   }),
  // );
  //   commit(MutationTypes.SET_TEAMS, teams);
  // },
  async [ActionTypes.EDIT_TEAM]({ commit, dispatch }, team) {
    // commit team data here
    if (!team || !team?.id) return;
    const preparedData = prepareData<Team>(team);
    return updateDoc(db.team(team.id), preparedData).then(() => {
      commit(MutationTypes.SET_TEAM, team);
      // dispatch(ActionTypes.GET_TEAMS);
    });
  },
  async [ActionTypes.DELETE_TEAM]({ dispatch }, team) {
    if (confirm(`Are you sure you want to delete ${team.name}?`)) {
      if (!team.id) return;
      deleteDoc(db.team(team.id)).then(() => {
        // dispatch(ActionTypes.GET_TEAMS);
        // TODO remove reference / DOM element? or will it go away...
      });
    }
  },
  async [ActionTypes.GET_PLAYERS]({ commit }) {
    // query teams
    const playersSnap = await getDocs(db.players);
    if (!playersSnap.empty) {
      commit(
        MutationTypes.SET_PLAYERS,
        playersSnap.docs.map((player) => ({ id: player.id, ...player.data() })),
      );
    }
  },
  async [ActionTypes.EDIT_PLAYER]({ dispatch }, player) {
    // commit team data here
    if (!player || !player?.id) return;
    const preparedData = prepareData<Partial<Player>>(player);
    return updateDoc(db.player(player.id), { ...preparedData }).then(() => {
      dispatch(ActionTypes.GET_PLAYERS);
    });
  },
  async [ActionTypes.DELETE_PLAYER]({ dispatch }, player) {
    if (
      confirm(
        `Are you sure you want to delete ${player.firstName} ${player.lastName}?`,
      )
    ) {
      if (!player.id) return;
      // TODO remove player from team if they are on one
      // should this be in a function?
      if (player?.team) {
        dispatch(ActionTypes.REMOVE_PLAYER_FROM_TEAM, {
          teamId: player.team,
          playerId: player.id,
        });
      }
      deleteDoc(db.player(player.id)).then(() => {
        dispatch(ActionTypes.GET_PLAYERS);
        // TODO remove reference / DOM element? or will it go away...
      });
    }
  },
  async [ActionTypes.ADD_PLAYER_TO_TEAM](_, { teamId, playerId }) {
    if (!teamId || !playerId) return;
    await updateDoc(db.team(teamId), { players: arrayUnion(playerId) });
  },
  async [ActionTypes.REMOVE_PLAYER_FROM_TEAM](_, { teamId, playerId }) {
    if (!teamId || !playerId) return;
    await updateDoc(db.team(teamId), { players: arrayRemove(playerId) });
  },
  async [ActionTypes.DELETE_RESERVATION]({ dispatch }, reservation) {
    if (!reservation?.id || !reservation?.contact?.email) return;
    if (confirm("are you sure?")) {
      await deleteDoc(db.reservation(reservation.id));
      dispatch(ActionTypes.GET_USER_RESERVATIONS, {
        email: reservation.contact.email,
      });
    }
  },
};
