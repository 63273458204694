import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const region = "us-west1";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwL4BW1N02LE3m3VbddRiAEInI6UPjZg8",
  authDomain: "sesc-16de1.firebaseapp.com",
  projectId: "sesc-16de1",
  storageBucket: "sesc-16de1.appspot.com",
  messagingSenderId: "152442337516",
  appId: "1:152442337516:web:fe747f2d5f9544caa90dc9",
  measurementId: "G-2ML5L6ZZ06",
};

const firebaseApp = initializeApp(firebaseConfig);
if (location.hostname === "localhost") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6LekvUwiAAAAADIYXwXhjZ0dF_P_D6z8iLdQWG6Q"),
  isTokenAutoRefreshEnabled: true,
});
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);
const fsdb = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, region);
const remoteConfig = getRemoteConfig(firebaseApp);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  teamPlayers: false,
};

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://admin.sesc.soccer/login",
  // This must be true.
  handleCodeInApp: false,
};

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(fsdb, "localhost", 8088);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export {
  firebaseApp,
  auth,
  fsdb,
  storage,
  actionCodeSettings,
  functions,
  remoteConfig,
};
