<script setup lang="ts">
import { RouterLink } from "vue-router";

import DashboardTeamsListItemNextPractice from "./DashboardTeamsListItemNextPractice.vue";

import type { Team, Season } from "@/types/sesc";
import { currentTeamLevel } from "@/utils";
import { computed, ref } from "vue";
import { getDoc, getDocs, query, where } from "firebase/firestore";
import db from "@/firebase/util";

const { team } = defineProps<{ team: Team }>();
const season = ref<Season>();

if (team.currentSeason) {
  const season_query = query(
    db.seasons(team.id as string),
    where("clubSeasonId", "==", team.currentSeason),
  );
  getDocs(season_query).then((season_snapshot) => {
    if (!season_snapshot.empty) {
      season.value = season_snapshot.docs[0].data();
    }
  });
}
</script>
<template>
  <li class="col-span-1">
    <router-link
      :to="`/teams/${team.id}`"
      class="flex h-full rounded-md shadow-sm hover:shadow-xl transition-shadow duration-200 hover:ring-2"
    >
      <div class="bg-black rounded-l-md relative w-48 overflow-hidden">
        <div
          :class="[
            'absolute top-0 left-0 z-0 w-full h-full flex-shrink-0 sm:basis-1/3 flex items-center justify-center w-48 text-white text-md font-medium rounded-l-md opacity-40',
          ]"
          :style="{
            backgroundColor: team.color as string,
            backgroundImage: `url(${team.photo}`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
            minHeight: `128px`,
          }"
          :data-color="team.color"
        ></div>
        <div class="relative top-0 left-0 w-full h-full z-10">
          <div class="flex-1 flex flex-col justify-start items-start px-4 py-4">
            <span class="text-xs uppercase text-white">{{
              currentTeamLevel({
                birthYear: team.birthYear,
                gender: team.gender,
              })
            }}</span>
            <span class="font-medium text-lg text-white hover:text-gray-600">{{
              team.name
            }}</span>
            <p v-if="season && season?.division" class="text-gray-500 text-sm">
              {{ season.division }}
            </p>

            <p
              class="mt-4 justify-self-end inline-flex rounded-full px-2 text-xs font-semibold leading-5"
              :class="
                (currentTeamLevel({
                  birthYear: team.birthYear,
                  asNumber: true,
                }) as number) >= 11
                  ? 'bg-blue-100 text-blue-800'
                  : 'bg-green-100 text-green-800'
              "
            >
              {{
                (currentTeamLevel({
                  birthYear: team.birthYear,
                  asNumber: true,
                }) as number) >= 11
                  ? "Competitive"
                  : "Development"
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="flex flex-1 items-stretch justify-between rounded-r-md border-t border-r border-b border-gray-200 bg-white"
      >
        <div class="flex-1 flex flex-col justify-start items-start px-4 py-4">
          <!-- <span class="text-xs uppercase">Next Practice:</span>
          <span class="font-medium text-lg text-gray-900 hover:text-gray-600">
            <DashboardTeamsListItemNextPractice :team="team" />
          </span>
          <p
            v-for="permit in (team?.currentSeason as Season)?.practicePermits"
            :key="permit.id"
            class="relative mt-4 text-sm z-20"
          >
            <a
              :href="permit.url"
              target="_blank"
              class="p-1 hover:bg-blue-200 underline"
              @click.stop
              >{{ permit.label }}</a
            >
          </p> -->
        </div>
      </div>
    </router-link>
  </li>
</template>
