<script setup lang="ts">
import HeaderBar from "@/components/ui/HeaderBar.vue";
import db from "@/firebase/util";

import { useCollection } from "vuefire";
import { query, where, Timestamp } from "firebase/firestore";
import { formatDate } from "date-fns";
import { DocumentIcon } from "@heroicons/vue/24/outline";
import BadgeUi from "./ui/BadgeUi.vue";

const permits_q = query(
  db.permits,
  where("startDate", "<=", Timestamp.fromDate(new Date())),
  where("endDate", ">=", Timestamp.fromDate(new Date())),
);
const { data: permits } = useCollection(permits_q, {
  ssrKey: "dashboard-permits",
});
</script>
<template>
  <div>
    <HeaderBar>
      <span class="text-white text-lg">Field Permits</span>
    </HeaderBar>
    <ul role="list" class="mt-6 grid grid-cols-1 gap-8 items-stretch">
      <li v-for="permit in permits" :key="permit.id">
        <a
          :href="permit.url"
          target="_blank"
          class="relative flex w-full justify-start items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-50 focus:z-10"
        >
          <DocumentIcon
            class="-ml-0.5 size-5 text-gray-400"
            aria-hidden="true"
          />
          {{ permit.label }}
          <BadgeUi
            color="blue"
            flat
            :border="false"
            small
            class="ml-auto self-end"
          >
            {{ formatDate(permit.startDate?.toDate() as Date, "MM/dd/yyyy") }}
            -
            {{ formatDate(permit.endDate?.toDate() as Date, "MM/dd/yyyy") }}
          </BadgeUi>
        </a>
      </li>
    </ul>
  </div>
</template>
