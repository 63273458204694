<script setup lang="ts">
import { computed, ref, watchEffect, onUnmounted } from "vue";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useStore } from "@/store";
import { MutationTypes } from "@/store/mutation-types";

const store = useStore();

const alert = computed(() => store.state.alert);
const hideTimeout = ref();
const closeTimeout = ref();

const alertReset = {
  show: false,
  title: "",
  message: "",
  error: false,
};

function hideAlert() {
  hideTimeout.value = setTimeout(closeAlert, 3300);
}

function closeAlert() {
  closeTimeout.value = setTimeout(() => {
    store.commit(MutationTypes.SET_ALERT, alertReset);
  }, 500);
}

function clearHiding() {
  if (hideTimeout.value) {
    clearTimeout(hideTimeout.value);
  }
  if (closeTimeout.value) {
    clearTimeout(closeTimeout.value);
  }
}

watchEffect(() => {
  if (alert.value?.show) {
    hideAlert();
  }
});

onUnmounted(() => {
  clearHiding();
});
</script>
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="alert.show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4" @mouseenter="clearHiding" @mouseleave="hideAlert">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <XCircleIcon
                  v-if="alert.error"
                  class="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
                <CheckCircleIcon
                  v-else
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ alert.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  {{ alert.message }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  @click="closeAlert"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
