<script setup lang="ts">
import { computed } from "vue";
import { useRoute, RouterLink } from "vue-router";
import {
  UsersIcon,
  CogIcon,
  DocumentIcon,
  UserGroupIcon,
  UserCircleIcon,
  VideoCameraIcon,
  SunIcon,
  CalendarDaysIcon,
  TicketIcon,
} from "@heroicons/vue/24/outline";

import type { Nav } from "@/types/sesc";

const route = useRoute();

const adminNav = [
  {
    name: "Teams",
    href: "/admin/teams",
    current: false,
    icon: UserGroupIcon,
  },
  {
    name: "Players",
    href: "/admin/players",
    current: false,
    icon: UserCircleIcon,
  },

  {
    name: "Users",
    href: "/admin/users",
    current: false,
    icon: UsersIcon,
  },

  {
    name: "Veo Reservations",
    href: "/admin/veo-reservations",
    current: false,
    icon: VideoCameraIcon,
  },
  {
    name: "Permits",
    href: "/admin/permits",
    current: false,
    icon: TicketIcon,
  },
  {
    name: "Files",
    href: "/admin/files",
    current: false,
    icon: DocumentIcon,
  },
  {
    name: "Seasons",
    href: "/admin/seasons",
    current: false,
    icon: SunIcon,
  },
  {
    name: "Practice Schedules",
    href: "/admin/practice-schedules",
    current: false,
    icon: CalendarDaysIcon,
  },
  {
    name: "Settings",
    href: "/admin/settings",
    current: false,
    icon: CogIcon,
  },
];

const nav = computed(() => {
  return adminNav.map((navItem: Nav) => {
    return { ...navItem, current: route.path == navItem.href };
  });
});
</script>
<template>
  <div class="border-gray-500 border-t py-2">
    <h3 class="text-white text-xs uppercase font-bold mb-2 pl-2 pt-2">Admin</h3>
    <router-link
      v-for="item in nav"
      :key="item.name"
      :to="item.href"
      :class="[
        item.current
          ? 'bg-gray-900 text-white'
          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
      ]"
    >
      <component
        :is="item.icon"
        :class="[
          item.current
            ? 'text-gray-300'
            : 'text-gray-400 group-hover:text-gray-300',
          'mr-3 flex-shrink-0 h-6 w-6',
        ]"
        aria-hidden="true"
      />
      {{ item.name }}
    </router-link>
  </div>
</template>
