<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useStore } from "@/store";
import HeaderBar from "@/components/ui/HeaderBar.vue";
import DashboardTeamsListItem from "./DashboardTeamsListItem.vue";
import db, { getTeams } from "@/firebase/util";

import type { Team } from "@/types/sesc";
import { useCollection } from "vuefire";

const store = useStore();

const userTeams = ref<Team[]>([]);
const user = computed(() => store.state.user);
const { data: teams } = useCollection(db.teams);

watchEffect(() => {
  if (user.value && teams.value) {
    const { id } = user.value;
    if (id) {
      const thisUsersTeams = teams.value.reduce(
        (teamsArr, team) => {
          if (team.coachesUidList && team.coachesUidList.includes(id)) {
            teamsArr.push({ ...team });
          }
          return teamsArr;
        },
        <Team[]>[],
      );
      userTeams.value = thisUsersTeams;
    }
  }
});
</script>
<template>
  <div v-if="userTeams && userTeams.length">
    <HeaderBar>
      <span class="text-white text-lg">Your Teams</span>
    </HeaderBar>
    <ul role="list" class="mt-6 grid grid-cols-1 gap-8 items-stretch">
      <DashboardTeamsListItem
        v-for="team in userTeams"
        :key="team.id"
        :team="team"
      />
    </ul>
  </div>
</template>
